
import { Component, Vue } from "vue-property-decorator";
import { IMembreData } from "@/api/types";
import { getMembres } from "@/api/membres";

@Component({
  name: "Membres",
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  //#region Datas
  private list: IMembreData[] = [];
  private listLoading = true;
  private listQuery = {
    page: 1,
    limit: 20,
  };
  //#endregion

  //#region Lifecycle hooks
  created() {
    this.getList();
  }
  //#endregion

  //#region Methods
  // Récupération de la liste des membres
  private async getList() {
    this.listLoading = true;
    try {
      const { data } = await getMembres(this.listQuery);
      this.list = data.items;
    } catch (error) {
    } finally {
      this.listLoading = false;
    }
  }

  // Redirection vers la page d'ajout de membre
  private AddMembre() {
    this.$router.push({ path: "/membres/add" });
  }
  //#endregion
}
